import React, { Fragment } from "react";
import Checkbox from "@mui/material/Checkbox";
import { chainTypeToString } from "services/Utils";

import HoverBox from "components/hoverbox/HoverBox.js";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Collapse,
  ListSubheader,
  ListItemIcon,
} from "@mui/material";
import { getFullProductName } from "../../../services/Utils";

class DetailFilterView extends React.Component {
  constructor(props) {
    super(props);
    this.updateFilterSettingsLocalStorage(this.props.chartType);
  }
  state = {
    open: {},
  };

  componentDidUpdate(prevProps) {
    if (this.props.chartType !== prevProps.chartType) {
      this.updateFilterSettingsLocalStorage(this.props.chartType);
    }
  }

  updateFilterSettingsLocalStorage(chartType) {
    const localStorageData = JSON.parse(sessionStorage.getItem("storesFilter"));
    // Decide what chartType , then act according to type when it comes to filter settings
    if (chartType === 1 || chartType === 2) {
      if (localStorageData === null) {
        const storesData = this.props.storesData.map((store) => {
          // checks store chain and type.
          // 0 = ICA, 3 = Coop, 9 = Ö&B, 11 = Rusta, 12 = Dollarstore
          if (
            (store.chain === 0 && store.online) || // ica
            (store.chain === 3 && store.online) || // coop
            store.type === "coop" ||
            store.chain === 9 || // öob
            store.chain === 11 || // rusta
            store.chain === 12 // dollarstore
          ) {
            store.active = false;
          }

          return store;
        });
        if (this.props.onStoresDataUpdated) {
          this.props.onStoresDataUpdated(storesData);
        }
      } else {
        this.props.storesData.forEach((x) => {
          x.active = localStorageData.includes(x.id);
        });

        if (this.props.onStoresDataUpdated) {
          this.props.onStoresDataUpdated(this.props.storesData);
        }
      }
    } else {
      const storesData = this.props.storesData.map((store) => {
        store.active = true;
        return store;
      });
      if (this.props.onStoresDataUpdated) {
        this.props.onStoresDataUpdated(storesData);
      }
    }
  }

  saveFilterToLocalStorage(storesData) {
    if (this.props.chartType == 3) {
      return;
    }

    const ids = storesData
      .filter((store) => store.active)
      .map((store) => store.id);
    sessionStorage.setItem("storesFilter", JSON.stringify(ids));
  }

  toggleChain(checked, chainId) {
    const storesData = this.props.storesData.map((store) => {
      if (store.chain === chainId) {
        store.active = checked;
      }

      return store;
    });

    this.saveFilterToLocalStorage(this.props.storesData);
    if (this.props.onStoresDataUpdated) {
      this.props.onStoresDataUpdated(storesData);
    }
  }

  toggleStore(checked, store) {
    const storesData = this.props.storesData;
    const index = storesData.indexOf(store);

    if (index !== -1) {
      storesData[index].active = checked;
    }

    this.saveFilterToLocalStorage(this.props.storesData);
    if (this.props.onStoresDataUpdated) {
      this.props.onStoresDataUpdated(storesData);
    }
  }

  toggleType(checked, typeId, chainCode) {
    if (!typeId) {
      return;
    }

    const storesData = this.props.storesData.map((store) => {
      if (store.type === typeId && store.chain === chainCode) {
        store.active = checked;
      }

      return store;
    });

    this.saveFilterToLocalStorage(this.props.storesData);
    if (this.props.onStoresDataUpdated) {
      this.props.onStoresDataUpdated(storesData);
    }
  }

  toggleAllStores(checked) {
    const storesData = this.props.storesData.map((store) => {
      store.active = checked;
      return store;
    });

    this.saveFilterToLocalStorage(this.props.storesData);
    if (this.props.onStoresDataUpdated) {
      this.props.onStoresDataUpdated(storesData);
    }
  }

  toggleProduct(checked, index) {
    const products = this.props.products;
    products[index].active = checked;
    if (this.props.onProductsUpdated) {
      this.props.onProductsUpdated(products);
    }
  }

  toggleAllProducts(checked) {
    const products = this.props.products.map((product) => {
      product.active = checked;
      return product;
    });

    if (this.props.onProductsUpdated) {
      this.props.onProductsUpdated(products);
    }
  }

  renderStore(store) {
    // Draw store list item

    return (
      <ListItemButton sx={{ pl: 6 }} key={store.id}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={store.active}
            onChange={(evt, checked) => {
              this.toggleStore(checked, store);
            }}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": 1 }}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <HoverBox popupText={store.info}>
              <span style={{ color: store.color }}>{store.name}</span>
            </HoverBox>
          }
        />
      </ListItemButton>
    );
  }

  renderType(type) {
    const open = this.state.open;

    if (!type.name) {
      const sortedStores = type.stores
        ? type.stores.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
          )
        : [];
      return sortedStores.map(this.renderStore.bind(this));
    }

    return (
      <List disablePadding>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <Checkbox
              edge="end"
              checked={type.active}
              onChange={(evt, checked) => {
                const chainCode = type.stores[0].chain;
                this.toggleType(checked, type.id, chainCode);
              }}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": 1 }}
            />
          </ListItemIcon>
          <ListItemButton
            sx={{ pl: 4 }}
            key={type.id}
            onClick={(evt) => {
              // this.toggleChain(checked, chain.id);
              evt.preventDefault();
              open[type.id] = !open[type.id];
              this.setState((prevState) => ({
                ...prevState,
                open,
              }));
            }}
          >
            <ListItemText primary={`${type.name} (${type.stores.length})`} />
            {open[type.id] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>

        {open[type.id] && this.renderType({ ...type, name: "" })}
      </List>
    );
  }

  render() {
    const products = this.props.products || [];

    const chainsMap = (this.props.storesData || []).reduce((acc, store) => {
      acc[store.chain] = acc[store.chain] || {
        types: {},
        id: store.chain,
        name: store.chainName || "Övrigt",
        count: 0,
        active: true,
      };

      acc[store.chain].types[store.type] = acc[store.chain].types[
        store.type
      ] || {
        active: true,
        id: store.type,
        name: chainTypeToString(store.type),
        stores: [],
      };

      acc[store.chain].active = acc[store.chain].active && store.active;
      acc[store.chain].count += 1;

      acc[store.chain].types[store.type].active =
        acc[store.chain].types[store.type].active && store.active;
      acc[store.chain].types[store.type].stores.push(store);
      return acc;
    }, {});
    const chains = Object.keys(chainsMap)
      .map((key) => chainsMap[key])
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    const open = this.state.open;

    return (
      <Fragment>
        <List
          disablePadding
          key={99999}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Affärer
            </ListSubheader>
          }
        >
          <ListItemButton key={99998}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={chains.every((x) => x.active)}
                onChange={(evt, checked) => {
                  // ALLA AFFÄRER
                  this.toggleAllStores(checked);
                }}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": 1 }}
              />
            </ListItemIcon>
            <ListItemText primary="Alla affärer" />
          </ListItemButton>

          {chains.map((chain) => (
            <React.Fragment key={chain.id}>
              <ListItem alignItems="flex-start" key={chain.id}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={chain.active}
                    onChange={(evt, checked) => {
                      this.toggleChain(checked, chain.id);
                    }}
                    tabIndex={-1}
                    disableRipple
                    name={chain.name}
                    inputProps={{ "aria-labelledby": 1 }}
                  />
                </ListItemIcon>
                <ListItemButton
                  key={chain.id}
                  onClick={(evt) => {
                    evt.preventDefault();
                    open[chain.id] = !open[chain.id];
                    this.setState((prevState) => ({
                      ...prevState,
                      open,
                    }));
                  }}
                >
                  <ListItemText primary={`${chain.name} (${chain.count})`} />
                  {open[chain.id] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              {[].concat.apply(
                [],
                Object.keys(chain.types).map((key, index) => (
                  <Collapse
                    key={index}
                    in={open[chain.id]}
                    timeout="auto"
                    unmountOnExit
                  >
                    {this.renderType(chain.types[key])}
                  </Collapse>
                )),
              )}
            </React.Fragment>
          ))}
        </List>
        <List>
          {
            <ListSubheader component="div" id="nested-list-subheader">
              Produkter
            </ListSubheader>
          }

          <ListItemButton key={Math.floor(Math.random() * 1000)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={products.every((x) => x.active)}
                onChange={(evt, checked) => this.toggleAllProducts(checked)}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": 1 }}
              />
            </ListItemIcon>
            <ListItemText primary="Alla produkter" />
          </ListItemButton>
          {products.map((product, index) => (
            <ListItemButton key={product.id}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={product.active}
                  onChange={(evt, checked) =>
                    this.toggleProduct(checked, index)
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": 1 }}
                />
              </ListItemIcon>
              <ListItemText primary={getFullProductName(product)} />
            </ListItemButton>
          ))}
        </List>
      </Fragment>
    );
  }
}

export default DetailFilterView;
